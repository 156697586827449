var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-intro-outro fill-height"},[_c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"flex-column fill-height",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"survey__header flex-grow-0 flex-shrink-0 pa-0"},[_c('v-container',{staticClass:"pa-0 pl-8 pr-8 py-2"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"align":"left"}},[_c('img',{staticClass:"survey__header-logo",attrs:{"src":require("@/assets/swift_bunny_logo_blue.svg")}})]),_c('v-col',{attrs:{"align":"right"}},[(_vm.survey.name != 'Company Profile Assessment')?_c('language-picker'):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"survey__body flex-grow-1 flex-shrink-1"},[_c('v-container',{staticClass:"pa-0 pl-8 pr-8",attrs:{"fill-height":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[(_vm.surveyContext.image_alt_text != null)?_c('v-row',{attrs:{"justify":"center"}},[_c('img',{staticClass:"header-image mb-4",attrs:{"src":_vm.imageSource,"alt":_vm.surveyContext.image_alt_text}})]):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('h3',{staticClass:"survey__body__headline white--text text-h4 text-center font-weight-bold mb-7"},[_vm._v(" "+_vm._s(_vm.surveyContext.headline)+" ")])]),_c('v-row',{staticClass:"mb-16",attrs:{"justify":"center"}},_vm._l((_vm.surveyContext.body_copy),function(body_copy,index){return _c('h5',{key:index,staticClass:"survey__body__paragraph white--text text-center text-h6 mb-2",domProps:{"innerHTML":_vm._s(body_copy)}})}),0),(_vm.pageContext == 'landing_page')?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"rounded-lg mb-8 pa-8 mt-v font-weight-medium text-h6 white--text",attrs:{"color":"green","x-large":"","to":{
                    name: this.$route.name,
                    params: { slug: _vm.survey_slug },
                    query: { section: 1 },
                  }}},[_vm._v(" "+_vm._s(_vm.isProfileAssessment ? "Proceed to Profile Assessment" : _vm.$store.state.translations.terms.start_the_survey)+" ")])],1):_vm._e(),(
                  _vm.pageContext == 'landing_page' &&
                    !_vm.isProfileAssessment &&
                    !_vm.isTestSurvey
                )?_c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{staticClass:"notice",attrs:{"dense":"","color":"rgba(255, 255, 255, .5)"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('cp-icon',{staticClass:"notice-icon",attrs:{"align":"top","icon":"notice-yellow"}})]},proxy:true}],null,false,2661905117)},[_c('h4',{staticClass:"black--text font-weight-medium text-subtitle"},[_vm._v(" "+_vm._s(_vm.surveyContext.confidentiality_copy)+" ")])])],1):_vm._e()],1)],1)],1)],1),_c('v-col',{staticClass:"survey__footer flex-grow-0 flex-shrink-0"},[_c('v-container',{staticClass:"pa-8 pl-8 pr-8"},[_c('h5',{staticClass:"text-center white--text"},[_c('a',{staticClass:"white--text",attrs:{"href":"mailto:support@swiftbunny.com"}},[_vm._v(" support@swiftbunny.com ")]),_vm._v(" | "),_c('a',{staticClass:"white--text",attrs:{"href":_vm.privacyPolicyUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$store.state.translations.terms.privacy_policy)+" ")]),_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Swift Bunny LLC "+_vm._s(_vm.$store.state.translations.terms.rights_reserved)+" ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }