import store from "@/store";

export default function canViewCPA(to, from, next) {
  store.dispatch("auth/fetchCurrentUser").then(() => {
    let user = store.state.auth.currentUser;

    if (!user.roles) return next("/");

    let userRoles = user.roles.map(x => x.slug);

    if (
      userRoles.includes("engage-program-manager") ||
      userRoles.includes("intouch-admin")
    ) {
      return next();
    }

    return next("/");
  });
}
