<template>
  <div class="survey-intro-outro fill-height">
    <v-container fill-height fluid class="pa-0">
      <v-row class="flex-column fill-height" justify="center" align="center">
        <v-col class="survey__header flex-grow-0 flex-shrink-0 pa-0">
          <v-container class="pa-0 pl-8 pr-8 py-2">
            <v-row align="center">
              <v-col align="left">
                <img
                  src="@/assets/swift_bunny_logo_blue.svg"
                  class="survey__header-logo"
                />
              </v-col>
              <v-col align="right">
                <language-picker
                  v-if="survey.name != 'Company Profile Assessment'"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col class="survey__body flex-grow-1 flex-shrink-1">
          <v-container fill-height class="pa-0 pl-8 pr-8">
            <v-row align="center" justify="center">
              <v-col>
                <v-row
                  v-if="surveyContext.image_alt_text != null"
                  justify="center"
                >
                  <img
                    :src="imageSource"
                    :alt="surveyContext.image_alt_text"
                    class="header-image mb-4"
                  />
                </v-row>
                <v-row justify="center">
                  <h3
                    class="survey__body__headline white--text text-h4 text-center font-weight-bold mb-7"
                  >
                    {{ surveyContext.headline }}
                  </h3>
                </v-row>
                <v-row justify="center" class="mb-16">
                  <h5
                    v-for="(body_copy, index) in surveyContext.body_copy"
                    :key="index"
                    class="survey__body__paragraph white--text text-center text-h6 mb-2"
                    v-html="body_copy"
                  ></h5>
                </v-row>
                <v-row v-if="pageContext == 'landing_page'" justify="center">
                  <v-btn
                    class="rounded-lg mb-8 pa-8 mt-v font-weight-medium text-h6 white--text"
                    color="green"
                    x-large
                    :to="{
                      name: this.$route.name,
                      params: { slug: survey_slug },
                      query: { section: 1 },
                    }"
                  >
                    {{
                      isProfileAssessment
                        ? "Proceed to Profile Assessment"
                        : $store.state.translations.terms.start_the_survey
                    }}
                  </v-btn>
                </v-row>
                <v-row
                  v-if="
                    pageContext == 'landing_page' &&
                      !isProfileAssessment &&
                      !isTestSurvey
                  "
                  justify="center"
                >
                  <v-alert dense color="rgba(255, 255, 255, .5)" class="notice">
                    <template v-slot:prepend>
                      <cp-icon
                        align="top"
                        icon="notice-yellow"
                        class="notice-icon"
                      />
                    </template>
                    <h4 class="black--text font-weight-medium text-subtitle">
                      {{ surveyContext.confidentiality_copy }}
                    </h4>
                  </v-alert>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col class="survey__footer flex-grow-0 flex-shrink-0">
          <v-container class="pa-8 pl-8 pr-8">
            <h5 class="text-center white--text">
              <a href="mailto:support@swiftbunny.com" class="white--text">
                support@swiftbunny.com
              </a>
              |
              <a :href="privacyPolicyUrl" target="_blank" class="white--text">
                {{ $store.state.translations.terms.privacy_policy }}
              </a>
              <p>
                © {{ new Date().getFullYear() }} Swift Bunny LLC
                {{ $store.state.translations.terms.rights_reserved }}
              </p>
            </h5>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    survey: { type: Object, required: true },
    pageContext: { type: String, required: true },
  },

  computed: {
    ...mapGetters(["copyright"]),

    survey_slug() {
      return this.$route.params.slug;
    },

    privacyPolicyUrl() {
      return `${process.env.VUE_APP_SITE_PATH}/privacy-policy`;
    },

    surveyContext() {
      if (!this.pageContext || !this.survey || !this.survey[this.pageContext]) {
        return {};
      } else {
        return this.survey[this.pageContext];
      }
    },

    imageSource() {
      return `https://storage.googleapis.com/assets-swiftbunny/email_assets/en/ingagement/${this.surveyContext.image_id}.png`;
    },

    isProfileAssessment() {
      return this.survey.name == "Company Profile Assessment";
    },

    isTestSurvey() {
      return this.survey.slug == "swift-bunny-test-survey";
    },

    isWebsiteMiniSurvey() {
      return this.survey.slug == "website-mini-survey";
    },
  },
};
</script>

<style scoped lang="scss">
.header-image {
  max-width: 80%;
  max-height: 400px;
  width: auto;
}

.notice-icon {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}

.notice .v-alert__wrapper {
  align-items: start;
}
</style>
